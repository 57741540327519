import ImageProloader from "image-preloader";
import bowser from "bowser";

const userData = bowser.parse(navigator.userAgent);
const isMobile = userData.platform.type === "mobile";
const isMac = userData.os.name.includes("mac");

const preloader = new ImageProloader();

const SECTION_DURATIONS = [60, 60, 60, 58];
const LAST_FRAME_RATIO = 10; // A number from 0 to 100. The duration of the last frame is relative to the rest
const SECTION_APPEARANCE_OFFSET = 35;

const fileList = SECTION_DURATIONS.map((size, folderIndex) =>
  new Array(size)
    .fill(0)
    .map(
      (_, fileIndex) =>
        `./images/media/${folderIndex + 1}/img${folderIndex + 1}_${
          fileIndex + 1
        }.jpg`
    )
).reduce((a, b) => [...a, ...b]);
let loaded = 0;
const loadedText = document.getElementById("loaded");
preloader.onProgress = () => {
  loaded++;
  loadedText.innerText = `Загружаем картинки... ${Math.floor(
    percentage(loaded, fileList.length)
  )}%`;
};
function hidePreloader() {
  const body = document.querySelector("body");
  const preloaderBody = document.querySelector(".page__loader");
  body.classList.remove("scroll-disabled");
  preloaderBody.remove();
}
if (!isMobile)
  preloader
    .preload(...fileList)
    .then(hidePreloader)
    .catch(() => {
      loadedText.innerText = `Ошибка загрузки. Обновите страницу`;
    });
else hidePreloader();

// Elements
const headerInfo = document.querySelector(".header__address");
const logo = document.querySelector(".logo");
const logoA = logo.querySelectorAll("img")[0];
const logoB = logo.querySelectorAll("img")[1];
const menuToggle = document.getElementById("menu-toggle");
const aside = document.querySelector("aside");
const lottie = document.getElementById("lottie");
const photo = document.getElementById("photo") as HTMLImageElement;
const map = document.getElementById("map");

const sections = Array.from(
  document.querySelectorAll<HTMLDivElement>("section[id]")
);
const photoSections = ["origin", "soviet", "nineties", "gallery"];

let lastSection = null;
let scrollNow = false;

window.addEventListener("resize", () => {
  const p = percentage(
    document.documentElement.scrollTop,
    document.documentElement.scrollHeight - window.innerHeight
  );
  // Header changes after resize (corner case)
  if (window.innerWidth >= 600) {
    if (p > 0.2) {
      if (!logoA.classList.contains("logo__image_small")) {
        headerInfo.classList.add("hidden");
        logoA.classList.add("logo__image_small");
        logoB.classList.add("hidden");
      }
    } else {
      if (logoA.classList.contains("logo__image_small")) {
        headerInfo.classList.remove("hidden");
        logoA.classList.remove("logo__image_small");
        logoB.classList.remove("hidden");
      }
    }
  } else {
    if (logoA.classList.contains("logo__image_small"))
      logoA.classList.remove("logo__image_small");
    if (logoB.classList.contains("hidden")) logoB.classList.remove("hidden");
  }
});

function updateImageSrc() {
  const currentSection = sections.find(
    (section) =>
      section.offsetTop + section.clientHeight >=
      document.documentElement.scrollTop
  );
  if (!currentSection) return;
  if (!photoSections.includes(currentSection.id)) return;

  const currentId = photoSections.indexOf(currentSection.id);
  const sectionScrollPercentage = percentage(
    document.documentElement.scrollTop -
      currentSection.offsetTop -
      SECTION_APPEARANCE_OFFSET,
    currentSection.clientHeight * ((100 - LAST_FRAME_RATIO) / 100)
  );
  const i = Math.ceil(
    getPart(
      sectionScrollPercentage < 100 - LAST_FRAME_RATIO
        ? sectionScrollPercentage / ((100 - LAST_FRAME_RATIO) / 100)
        : 100,
      SECTION_DURATIONS[currentId]
    )
  );
  photo.src = `./images/media/${currentId + 1}/img${currentId + 1}_${
    i <= 0 ? 1 : i + 1 > SECTION_DURATIONS[currentId] ? i : i + 1
  }.jpg`;
}

if (isMac) setInterval(() => updateImageSrc(), 10);
else window.addEventListener("scroll", () => updateImageSrc());

window.addEventListener("scroll", () => {
  scrollNow = true;

  const currentSection = sections.find(
    (section) =>
      section.offsetTop + section.clientHeight >=
      document.documentElement.scrollTop
  );
  if (lastSection != currentSection) {
    lastSection = currentSection;
    window.location.hash = "#" + currentSection.id;
    const prevIcon = document.querySelector(`.menu__link.selected`);
    prevIcon.classList.remove("selected");
    const icon = document.querySelector(`.menu__link#${currentSection.id}`);
    icon.classList.add("selected");
  }
  setTimeout(() => {
    scrollNow = false;
  }, 10);

  if (window.innerWidth <= 599) return;
  const p = percentage(
    document.documentElement.scrollTop,
    document.documentElement.scrollHeight - window.innerHeight
  );

  if (window.innerWidth < 600) return;
  if (p > 0.2) {
    if (!logoA.classList.contains("logo__image_small")) {
      headerInfo.classList.add("hidden");
      logoA.classList.add("logo__image_small");
      logoB.classList.add("hidden");
    }
  } else {
    if (logoA.classList.contains("logo__image_small")) {
      headerInfo.classList.remove("hidden");
      logoA.classList.remove("logo__image_small");
      logoB.classList.remove("hidden");
    }
  }

  if (currentSection.id === "intro") {
    if (lottie.classList.contains("hidden")) lottie.classList.remove("hidden");
  } else {
    if (!lottie.classList.contains("hidden")) lottie.classList.add("hidden");
  }
  if (currentSection.id === "contacts") {
    if (map.classList.contains("hidden")) map.classList.remove("hidden");
  } else {
    if (!map.classList.contains("hidden")) map.classList.add("hidden");
  }
  if (photoSections.includes(currentSection.id)) {
    if (photo.classList.contains("hidden")) photo.classList.remove("hidden");
  } else {
    if (!photo.classList.contains("hidden")) photo.classList.add("hidden");
  }
});

window.addEventListener("load", () => {
  document.querySelectorAll(".carousel").forEach((carousel) => {
    new Swiper("#" + carousel.id, {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        prevEl: `#${carousel.id} .controls.left`,
        nextEl: `#${carousel.id} .controls.right`,
      },
      pagination: {
        el: `#${carousel.id}-pagination`,
        clickable: true,
      },
    });
  });
  ymaps.ready(() => {
    const mainMap = new ymaps.Map("map", {
      center: [53.215595, 50.148308],
      zoom: 19,
    });
    mainMap.controls.remove("searchControl");
    mainMap.behaviors.disable("scrollZoom");

    const mobileMap = new ymaps.Map("mobile_map", {
      center: [53.215595, 50.148308],
      zoom: 14,
    });
    const pin = new ymaps.Placemark([53.215595, 50.148308]);
    mobileMap.geoObjects.add(pin);
    mobileMap.controls.remove("searchControl");
    mobileMap.behaviors.disable("scrollZoom");
    mobileMap.behaviors.disable("drag");
  });

  Array.from(document.getElementsByClassName("lottie")).forEach((container) => {
    bodymovin.loadAnimation({
      container,
      path: process.env.LOTTIE_FILE,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
  });

  const to = window.location.hash.replace("#", "");
  if (!to) return;
  const scrollTo = sections.find((section) => section.id === to);
  window.scrollTo({
    top:
      to === "contacts" && window.innerWidth >= 600
        ? scrollTo.offsetTop + 85 + 35
        : scrollTo.offsetTop,
    behavior: "smooth",
  });
});

window.addEventListener("popstate", () => {
  if (scrollNow) return;
  const to = window.location.hash.replace("#", "");
  const scrollTo = sections.find((section) => section.id === to);
  window.scrollTo({
    top:
      to === "contacts" && window.innerWidth >= 600
        ? scrollTo.offsetTop + 85 + 35
        : scrollTo.offsetTop,
    behavior: "smooth",
  });
});

menuToggle.addEventListener("click", () => {
  const icons = menuToggle.querySelectorAll("img");
  if (icons[1].classList.contains("hidden")) {
    icons[0].classList.add("hidden");
    icons[1].classList.remove("hidden");
    aside.setAttribute("data-visibility", "visible");
  } else {
    icons[0].classList.remove("hidden");
    icons[1].classList.add("hidden");
    aside.setAttribute("data-visibility", "hidden");
  }
});
document.querySelectorAll(".menu__item").forEach((item) =>
  item.addEventListener("click", () => {
    const icons = menuToggle.querySelectorAll("img");
    icons[0].classList.remove("hidden");
    icons[1].classList.add("hidden");
    aside.setAttribute("data-visibility", "hidden");
  })
);

function percentage(a, b) {
  return (a * 100) / b;
}

function getPart(percentage, num) {
  return (num * percentage) / 100;
}
